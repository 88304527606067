import React from "react";
import style from "./WorkProgress.module.css";
export default function WorkProgress() {
  const heading = (
    <>
      OUR <strong>WORKING </strong> PROCESSS
    </>
  );
  const description = (
    <>
      With outsourcing software development, we not only meet your requirements
      but build online solutions that can:<br></br>Boost your profitability. Get
      you noticed. Put you ahead of your rivals.
    </>
  );
  return (
    <div className={style.mainContainer}>
      <div className={style.innerContainer}>
        <div className={style.textContainer}>
          <h1 className={style.heading}>{heading}</h1>
          <p className={style.description}>{description}</p>
        </div>
        <div className={style.imgContainer}>
          <div className={style.inImgContainerDown}>
            <img
              alt="process Img"
              src="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/04/meeting.svg"
              className={style.downImg}
            ></img>
          </div>
          <div className={style.inImgContainerTop}>
            <img
              alt="process Img"
              src="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/04/proposal.svg"
              className={style.downImg}
            ></img>
          </div>
          <div className={style.inImgContainerDown}>
            <img
              alt="process Img"
              src="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/04/design-min.png"
              className={style.downImg}
            ></img>
          </div>
          <div className={style.inImgContainerTop}>
            <img
              alt="process Img"
              src="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/05/development-1.png"
              className={style.downImg}
            ></img>
          </div>
          <div className={style.inImgContainerDown}>
            <img
              alt="process Img"
              src="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/04/testing-1.svg"
              className={style.downImg}
            ></img>
          </div>
          <div className={style.inImgContainerCenter}>
            <img
              alt="process Img"
              src="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/04/go-live.svg"
              className={style.centerImg}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
