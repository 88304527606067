import React, { useEffect } from "react";
import * as display from "./AboutImp";

export default function About({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [title]);
  return (
    <div>
      <display.Main
        heading="About Us"
        description="Your Outsourcing Software Development Partners"
      />
      <display.OutSource />
      <display.Pillars />
      {/* <display.Reason /> */}
      {/* <display.CoreTeam /> */}
    </div>
  );
}
