const data = [
  {
    p1: {
      heading: "Customized Education Software Development",
      description:
        "Our extensive knowledge in the e-learning sector enables us to program and create learning apps with several features. These elements help reduce any discrepancies people face during distant learning. Our experience is present in providing multimedia, messaging, and video conferencing. However, other features including VoIP, recording, streaming, discussion boards, etc also fall under our strength."
    },
    p2: {
      heading: "Interactive Board Sessions",
      description:
        "Interactive education is the next big thing in this sector and we are already seeing the evidence around us. This is why we offer our education software development service to build customized animated simulations combined with 2D and 3D graphical effects. It is a fact that adding interactivity increases focus, and this is how we make this possible. From building avatars, gamification of content, and portal development, to web-based learning, payment modules and student monitoring tools: <strong>buggcy</strong> is the choice."
    },
    imgSrc:
      "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/education2-1.png"
  }
];

export default data;
