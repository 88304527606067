import React, { useState } from "react";
import style from "./JobsPanel.module.css";
import JobsList from "./JobsList/JobsList";
import jobsData from "./JobsData";
import Filter from "./Filter";
function JobsPanel() {
  const [searchData, setSearchData] = useState(jobsData);
  const [filterData, setFilterData] = useState(searchData);

  return (
    <div className={style.pannelWrapper}>
      <div className={style.textDiv}>
        <h2 className={style.heading}>
          Open <strong>Jobs</strong>
        </h2>
        <p className={style.description}>
          Are you passionate about building human-centered products? <br></br>We
          love building great things together, and we can’t wait to meet you.
        </p>
      </div>

      <Filter
        listData={filterData}
        setListData={setFilterData}
        searchData={searchData}
        setSearchData={setSearchData}
      />

      <div className={style.jobsList}>
        <JobsList listData={filterData} />
      </div>
    </div>
  );
}

export default JobsPanel;
