import React from "react";
import style from "./Life.module.css";
import cx from "classnames";
import one from "../../../assets/img/1.jpg";
import two from "../../../assets/img/2.jpg";
import three from "../../../assets/img/3.jpg";
import four from "../../../assets/img/4.jpg";
import five from "../../../assets/img/5.jpg";
import six from "../../../assets/img/6.jpg";
import seven from "../../../assets/img/7.jpg";
import eight from "../../../assets/img/8.jpg";
import nine from "../../../assets/img/9.jpg";
import ten from "../../../assets/img/10.jpg";
import eleven from "../../../assets/img/11.jpg";
import twelve from "../../../assets/img/12.jpg";
function Life() {
  return (
    <div className={style.wrapper}>
      <div className={style.leftPanel}>
        <div className={style.textContainer}>
          <h1 className={style.heading}>
            Life At <strong>buggcy</strong>
          </h1>
          <p className={style.description}>
            We value a passion for learning, will to succeed, and a desire for
            challenge. At <strong>buggcy</strong>, we want individuals who want
            to perfect their talents and explore new avenues. Our team consists
            of smart individuals who are motivated to learn the latest
            programming technologies. In return, they enjoy flexibility and
            benefits, letting them explore the career they want to build. This
            is our criteria, and if you think you fit then we welcome you!
          </p>
        </div>
        <div className={cx(style.imageContainer, style.leftContainer)}>
          <div className={style.firstContainer}>
            <img src={one} alt="1st img" className={style.first}></img>
            <img src={two} alt="" className={style.second}></img>
          </div>
          <div className={style.secondContainer}>
            <img src={three} alt="" className={style.first}></img>
            <img src={four} alt="" className={style.second}></img>
          </div>
          <div className={style.thirdContainer}>
            <img src={five} alt="" className={style.first}></img>
            <img src={six} alt="" className={style.second}></img>
          </div>
        </div>
      </div>
      <div className={cx(style.imageContainer, style.rightContainer)}>
        <div className={style.firstContainer}>
          <img src={seven} alt="" className={style.first}></img>
          <img src={eight} alt="" className={style.second}></img>
        </div>
        <div className={style.secondContainer}>
          <img src={nine} alt="" className={style.first}></img>
          <img src={ten} alt="" className={style.second}></img>
        </div>
        <div className={style.thirdContainer}>
          <img src={eleven} alt="" className={style.first}></img>
          <img src={twelve} alt="" className={style.second}></img>
        </div>
      </div>
    </div>
  );
}

export default Life;
