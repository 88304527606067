import React from "react";
import style from "./Card.module.css";
function Card({ imgSrc, heading, description }) {
  return (
    <div className={style.singleCard}>
      <div className={style.imgDiv}>
        <img alt="" src={imgSrc} className={style.cardFig} />
      </div>
      <div className={style.cardDescDiv}>
        <h3 className={style.heading}>{heading}</h3>
        <p className={style.description}>{description}</p>
      </div>
    </div>
  );
}

export default Card;
