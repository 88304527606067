import React from "react";
import styles from "../Widget.module.css";
import style from "../Industries/Indutries.module.css";
import { Link } from "react-router-dom";
export default function QuickLinks() {
  return (
    <div>
      <div className={style.titleDiv}>
        <h2 className={styles.widgetTitle}>Quick Links</h2>
      </div>
      <div className={style.listDiv}>
        <ul className={style.listMainContainer}>
          <div className={style.listInnerDiv}>
            <li className={style.listItem}>
              <Link to="/about" className={style.link}>
                About Us
              </Link>
            </li>
          </div>
          <div className={style.listInnerDiv}>
            <li className={style.listItem}>
              <Link to="/careers" className={style.link}>
                Careers
              </Link>
            </li>
          </div>
          <div className={style.listInnerDiv}>
            <li className={style.listItem}>
              <Link to="/contact-us" className={style.link}>
                Contact Us
              </Link>
            </li>
          </div>
          <div className={style.listInnerDiv}>
            <li className={style.listItem}>
              <Link to="/blog" className={style.link}>
                Blogs
              </Link>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
}
