import React from "react";
const data = [
  {
    toggle: {
      title: "Designing, Building & Launching Great Digital Products",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "Save time and money by allowing us to build unique interactive features that will take your UI UX design experience to a whole new level. At <strong>buggcy</strong>, we believe that an immersive digital experience comes when everything is bound together with glue. This glue will hold together all connecting devices, channels, and touchpoints. Similarly, with our strain-hardened team of expert designers in UX and UI, we create turnkey solutions that are not just innate but fall exactly as the clients wanted them."
    },
    key: "0"
  },
  {
    toggle: {
      title: "UI UX Design Research & Analysis",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-blue.svg"
    },
    content: {
      para: (
        <>
          We work together to understand clients and what business goals they
          have. We evaluate their current situation using different tools like
          Google Analytics, check their bug reports and then implement solutions
          based on how they want, how their competition works and case studies.
          <br></br>
          This way we are trying to establish a deep understanding of the
          customer base, how they behave and their attitudes. We will utilize
          the current market trends and reviews of your competitions. Finally,
          mixing your particular and general business requirements, we form a
          splendid insight into the UI UX designs that will work best for your
          product. This way, we are not only working on improving your current
          design but also effectively optimizing it.
        </>
      )
    },
    key: "1"
  },
  {
    toggle: {
      title: "A Great UX Design",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: (
        <>
          When designing products, we ensure a high-quality prototype, complete
          with layouts, information and UI components. We like to test out what
          we built through feedback from clients and end-users to perfect our
          design and interface.<br></br> In UI UX design, we believe that the
          end-user should be at the centre of the design process. Apart from the
          consideration of clients’ target audience, we figure out how the
          front-end design will gain interaction from visitors through our
          modern testing methods in interaction design. Utilizing usability
          audits, we offer the best solutions to your front-end UX design needs.
          We root or design process through iteration and collaboration. This is
          coupled with an open and Agile communication approach which makes our
          work stand out from the rest.
        </>
      )
    },
    key: "2"
  },
  {
    toggle: {
      title: "A Responsive UI Design",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/disk-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/disk-blue.svg"
    },
    content: {
      para: "An application which is not responsive to other platforms is only half complete. Responsiveness is a key component in building a great UI UX design for web products, may it be enterprise systems or consumer applications. We focus on consistency across all platforms so that users of all types (Apple, Google, Linux, or Windows) feel the handicraft involved and our attention to detail. A responsive design makes sure that everything that our front-end designers develop is purposeful and enjoyable. The UI design of any online service is focussed on how end-consumers are going to interact with it. Modern technology dictates that user traffic can come from tablets, PCs, laptops, and mobile phones. Let <strong>buggcy</strong> take care of your users/customers and allow us to build a product that serves as a maximum satisfaction to them."
    },
    key: "3"
  },
  {
    toggle: {
      title: "Information Architecture",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-blue.svg"
    },
    content: {
      para: "Whether there is a need for information systems for internal systems, application database or website, <strong>buggcy</strong> is the solution. When designing blueprints for information architecture, we consider all areas like usefulness, UI UX design and navigation so users can enjoy what you offer rather than struggle to understand what it is entirely."
    },
    key: "4"
  }
];

export default data;
