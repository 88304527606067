import React, { useEffect } from "react";
import style from "./Blog.module.css";
import Main from "../Main/Main";
// import NavBar from "./NavBar/NavBar";
// import TopSection from "./TopSection/TopSection";
// import ContentCards from "./ContentCards/ContentCards";
// import ContentCardsSpecific from "./ContentCards/ContentCardsSpecific";
// import BlogData from "./BlogData";
// import BlogPost from "./BlogPost/BlogPost";
// import { Route, Switch } from "react-router-dom";
import Search from "./BlogSearch/BlogSearch";

function Blog({ title, history }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      left: 0,
      right: 0,
      behavior: "smooth"
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="BLOG"
        description={
          <>
            <span className={style.description}>
              Catch top stories, Stay updated with trend.
            </span>
            <br></br>
            <Search history={history} />
          </>
        }
      />
      <div className={style.bottomEmptyContainer}>
        <h2>No blog post available!</h2>
      </div>
      {/* <Switch>
        <Route path="/blog/post">
          <BlogPost data={BlogData[0]} />
        </Route>
        <Route path="/blog/" exact>
          <NavBar />
          <TopSection />
          <ContentCards cardData={BlogData} />
        </Route>
        <Route
          path="/blog/:category"
          component={ContentCardsSpecific}
          exact
        ></Route>
        <Route path="/blog/:category/:post" component={BlogPost} exact></Route>
      </Switch> */}
    </div>
  );
}

export default Blog;
