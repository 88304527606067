import React from "react";
import style from "./Invalid.module.css";
// import Main from "../Main/Main";
import { Link } from "react-router-dom";
function InvalidRoute() {
  return (
    <div className={style.mainWrapper}>
      <div className={style.topDiv}></div>
      <div className={style.wrapper}>
        <div className={style.imageDiv}>
          <h1 className={style.heading}>ERROR 404</h1>
        </div>
        <div className={style.description}>
          <h2 className={style.center}>Hmmm... It Looks Like You're Lost!</h2>
          <h5 className={style.center}>
            The page you are looking for is not available.
          </h5>
          <Link to="/" className={style.centerBtn}>
            <button className={style.button}>Go To Home</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default InvalidRoute;
