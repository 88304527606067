import axios from "axios";
import { toast } from "react-toastify";
const toastSettings = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light"
};
const url =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4001"
    : "https://distinct-dove-underwear.cyclic.app";
const sendEmail = async ({
  message,
  name,
  email,
  subject,
  isContact,
  setData,
  setButton,
  initState
}) => {
  axios
    .post(`${url}/api/v1/send-email`, {
      message,
      name,
      email,
      subject,
      isContact
    })
    .then(data => {
      toast.success("Email Sent, We'll get back to you!", toastSettings);
      setData(initState);
      setButton(false);
    })
    .catch(error => {
      toast.error("oops, Something went wrong!", toastSettings);
      setButton(false);
    });
};
export default sendEmail;
