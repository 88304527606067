import React from "react";
import Main from "../../../Main/Main";
import JobsData from "../JobsData";
import JobsPost from "./JobsPost";
import { filterPost } from "../../../Blog/ArrayFilter";
function JobsDetail({ match }) {
  const data = filterPost(JobsData, match.params.title);
  return (
    <div>
      <Main heading={data[0].title} />
      <JobsPost data={data[0]} />
    </div>
  );
}

export default JobsDetail;
