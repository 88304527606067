import React from "react";
import style from "./Widget.module.css";
import SocialLinks from "./socialLinks/socialLinks";
import GlobalHead from "./Global/GlobalHead";
import Industries from "./Industries/Industries";
import QuickLinks from "./QuickLinks/QuickLinks";

export default function Widgets() {
  return (
    <div className={style.mainContainer}>
      <div className={style.innerContainer}>
        <div className={style.singContainer}>
          <SocialLinks />
        </div>
        <div className={style.singContainer}>
          <GlobalHead />
        </div>
        <div className={style.singContainer}>
          <Industries />
        </div>
        <div className={style.singContainer}>
          <QuickLinks />
        </div>
      </div>
    </div>
  );
}
