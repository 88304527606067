import React, { useState } from "react";
import style from "./JobApply.module.css";

function Form() {
  const initState = [
    {
      name: "",
      email_address: "",
      phoneNo: "",
      postingAplyFor: "",
      message: "",
      file: "",
    },
  ];

  const [userData, setuserData] = useState(initState);
  const updateData = e => {
    setuserData({ ...userData, [e.target.name]: e.target.value });
  };
  return (
    <div className={style.mainContainer}>
      <div className={style.innerContainer}>
        <div className={style.fieldsMainContainer}>
          <div className={style.filedsInnerContainer}>
            <h2 className={style.heading}>
              Submit your <strong>Application</strong>
            </h2>
            <form className={style.form}>
              <input
                className={style.inputArea}
                type="text"
                placeholder="Full Name*"
                name="name"
                value={userData.name}
                required
                onChange={e => {
                  updateData(e);
                }}
              ></input>
              <input
                className={style.inputArea}
                type="text"
                placeholder="Email*"
                name="email_address"
                value={userData.email_address}
                required
                onChange={e => {
                  updateData(e);
                }}
              ></input>
              <input
                className={style.inputArea}
                type="text"
                placeholder="Phone No*"
                name="phoneNo"
                value={userData.phoneNo}
                onChange={e => {
                  updateData(e);
                }}
                required
              ></input>
              <input
                className={style.inputArea}
                type="text"
                placeholder="Posting Applying For*"
                name="postingAplyFor"
                value={userData.postingAplyFor}
                onChange={e => {
                  updateData(e);
                }}
                required
              ></input>

              <textarea
                className={style.message}
                type="text"
                placeholder="Message*"
                cols="40"
                rows="10"
                name="message"
                value={userData.message}
                size="40"
                onChange={e => {
                  updateData(e);
                }}
                required
              ></textarea>
              <input
                type="file"
                name="file"
                value={userData.file}
                className={style.uploadBtn}
                onChange={e => updateData(e)}
              ></input>
              <button type="submit" className={style.submitBtn}>
                Submit Now
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;
