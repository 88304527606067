import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import style from "./Form.module.css";
import loading from "../../../assets/img/spinner.svg";

import sendEmail from "../../../api-calls/send-email";
function Form() {
  const initState = {
    name: "",
    email: "",
    subject: "",
    message: "",
    isContact: true
  };
  const [userData, setuserData] = useState(initState);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const onSubmit = e => {
    setButtonDisabled(true);
    e.preventDefault();

    if (
      userData.name === "" ||
      userData.email === "" ||
      userData.subject === "" ||
      userData.message === ""
    )
      return;

    sendEmail({
      ...userData,
      setButton: setButtonDisabled,
      initState,
      setData: setuserData
    });
  };
  return (
    <div className={style.mainContainer}>
      <ToastContainer />
      <div className={style.innerContainer}>
        <div className={style.fieldsMainContainer}>
          <div className={style.filedsInnerContainer}>
            <h2 className={style.heading}>
              Let's <strong>Connect</strong>
            </h2>
            <form className={style.form} onSubmit={onSubmit}>
              <input
                className={style.inputArea}
                type="text"
                placeholder="Full Name*"
                area-invalid="false"
                name="your-name"
                value={userData.name}
                size="40"
                required
                onChange={e =>
                  setuserData({
                    ...userData,
                    name: e.target.value
                  })
                }
              ></input>
              <input
                className={style.inputArea}
                type="email"
                placeholder="Email*"
                area-invalid="false"
                name="your-email"
                value={userData.email}
                size="40"
                required
                onChange={e =>
                  setuserData({
                    ...userData,
                    email: e.target.value
                  })
                }
              ></input>
              <input
                className={style.inputArea}
                type="text"
                placeholder="Subject*"
                area-invalid="false"
                name="your-subject"
                value={userData.subject}
                required
                onChange={e =>
                  setuserData({
                    ...userData,
                    subject: e.target.value
                  })
                }
              ></input>
              <textarea
                className={style.message}
                type="text"
                placeholder="Message*"
                area-invalid="false"
                cols="40"
                rows="10"
                name="your-message"
                value={userData.message}
                size="40"
                required
                onChange={e =>
                  setuserData({
                    ...userData,
                    message: e.target.value
                  })
                }
              ></textarea>

              <button
                type="submit"
                className={`${style.submitBtn} ${
                  buttonDisabled && style.disabled
                }`}
                disabled={buttonDisabled}
              >
                {buttonDisabled ? (
                  <img src={loading} width="32px" height="auto" />
                ) : (
                  "Send"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;
