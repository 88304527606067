const webDevData = [
  {
    toggle: {
      title: "Travel & Hospitality Redefined",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "With <strong>buggcy</strong>, there is no need to look further to upscale your booming travel and tourism business. Our exciting travel business solutions empower your business with technical consultancy, customer portals and a back-office management system. We strive to bridge the gap between travel companies and their customers so that customer can explore freely, and the companies can enjoy bigger opportunities. Remember, the tourism and travel sector is a booming sector of the world economies, and those who make the right decisions can lead."
    },
    key: "0"
  },
  {
    toggle: {
      title: "Get a Personalized Back Office Experience",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: "Why go for a single look of your online platform when you have an option to make multiple changes according to your business? We can provide personalized back-office user experience, an admin panel, where the travel companies can change their website style according to the theme or seasons they are going for. The objective is simple, travellers require convenience, appearance and quality service. Services that are a single push of a button away are valued more than filling never-ending online forms. The greater the User Experience, the bigger your online business."
    },
    key: "1"
  },
  {
    toggle: {
      title: "A Great Travel Tech Engineering Experience",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-blue.svg"
    },
    content: {
      para: "Our work with different travel and hospitality businesses around the globe have given us necessary knowledge about travel management solutions, data collection and custom software needs. Our core expertise lies in organizing the initiative and IT strategy of our valued client from this industry. Taking complete responsibility to implement a foolproof strategy."
    },
    key: "2"
  }
];

export default webDevData;
