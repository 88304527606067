import React from "react";
import { Route, Switch } from "react-router-dom";
// import style from "./Industries.module.css";
import WorkProcess from "../Home/WorkProgress/WorkProgress";
import * as displays from "./IndustriesImp";
function Industries() {
  return (
    <div>
      <Switch>
        <Route path="/industries/on-demand-services">
          <displays.OnDemand title="On-Demand Services | buggcy" />
        </Route>
        <Route path="/industries/healthcare">
          <displays.HealthCare title="Healthcare | buggcy" />
        </Route>
        <Route path="/industries/food-groceries">
          <displays.Food title="Food & Groceries | buggcy" />
        </Route>
        <Route path="/industries/travel-tourism">
          <displays.Travel title="Travel & Tourism | buggcy" />
        </Route>
        <Route path="/industries/e-commerce">
          <displays.Ecom title="E-Commerce | buggcy" />
        </Route>
        <Route path="/industries/education">
          <displays.Education title="Education | buggcy" />
        </Route>
        <Route path="/industries/finance">
          <displays.Finance title="Finance | buggcy" />
        </Route>
        <Route path="/industries/">
          <displays.OnDemand title="On-Demand Services | buggcy" />
        </Route>
        <WorkProcess />
      </Switch>
    </div>
  );
}

export default Industries;
