const webDevData = [
  {
    toggle: {
      title: "Buying Through Innovation",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "The food & grocery business doesn’t have to stick to the way it has been in the past. Modern solutions state the need for software that redefines how groceries are bought, sold and business operations are done. With <strong>buggcy</strong> you are not only re-evaluating your potential for online business but also taking care of your day-to-day operations, grocery management solutions and backend processes. We enable you to do this so that you run your business smoothly and generate bigger revenue."
    },
    key: "0"
  },
  {
    toggle: {
      title: "E-Commerce Software for Groceries",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: "The food & grocery business doesn’t have to stick to the way it has been in the past. Modern solutions state the need for software that redefines how groceries are bought, sold and business operations are done. With <strong>buggcy</strong> you are not only re-evaluating your potential for online business but also taking care of your day-to-day operations, grocery management solutions and backend processes. We enable you to do this so that you run your business smoothly and generate bigger revenue."
    },
    key: "1"
  },
  {
    toggle: {
      title: "Track Your Inventory",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-blue.svg"
    },
    content: {
      para: "With the right type of store inventory software that integrates with your website and mobile apps, keep track of all good and let your customers know about it as well. With features like fleet management, Electronic Proof of Delivery (e-POD) and other warehousing systems <strong>buggcy</strong> provides answers to everything."
    },
    key: "2"
  }
];

export default webDevData;
