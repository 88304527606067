import React from "react";
import style from "./JobsList.module.css";
import { Link } from "react-router-dom";
import LinkGen from "../../../Blog/LinkGen";
import Image from "../../../../assets/img/short-logo.png";
function JobsList({ listData }) {
  return (
    <ul className={style.listWrapper}>
      {listData.length === 0 ? (
        <li className={style.noData}>No Data to Display</li>
      ) : (
        listData.map(
          (data, index) => (
            <li key={index} className={style.list}>
              <Link
                to={`/job/${LinkGen(data.url)}`}
                className={style.singleItem}
              >
                <div className={style.jobListing}>
                  <div className={style.logoDiv}>
                    <img alt="" src={Image} className={style.fig}></img>
                  </div>
                  <div className={style.textDiv}>
                    <h2 className={style.jobTitle}>{data.title}</h2>
                    <div className={style.company}>
                      <strong>buggcy</strong>

                      <span className={style.tagLine}>
                        Turning your digital dreams into reality.
                      </span>
                    </div>
                  </div>
                </div>
                <div className={style.detailsWrapper}>
                  <div className={style.cityDiv}>
                    <span className={style.city}>{data.city}</span>
                  </div>
                  <div className={style.detail}>
                    <h2
                      className={`${style.type} ${
                        data.partTime
                          ? `${style.partTime}`
                          : `${style.fullTime}`
                      }`}
                    >
                      {data.type}
                    </h2>
                    <span className={style.date}>Posted on {data.date}</span>
                  </div>
                </div>
              </Link>
            </li>
          ),
          []
        )
      )}
    </ul>
  );
}

export default JobsList;
