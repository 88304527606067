import React from "react";
import style from "./Card.module.css";

export default function Card({ imgSrc, heading, description }) {
  return (
    <div className={style.mainCard}>
      <div className={style.innerCard}>
        <div className={style.figure}>
          <img alt="cardImg" src={imgSrc} className={style.cardImg}></img>
        </div>
        <div className={style.textArea}>
          <h1 className={style.heading}>{heading}</h1>
          <p className={style.description}>{description}</p>
        </div>
      </div>
    </div>
  );
}
