import React, { useEffect } from "react";
import DataScienceData from "./DataScienceData";
import AccordComp from "../TempComponent/TempComponents";
import Main from "../../../Main/Main";
function DataScience({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="BIG DATA & DATA SCIENCE"
        description="Drive Your Business Forward Using Big Data"
      />
      <AccordComp
        imgSrc="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/big-data.png"
        AccordData={DataScienceData}
      />
    </div>
  );
}

export default DataScience;
