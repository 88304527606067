import React from "react";
import styles from "../Widget.module.css";
import style from "./socialLinks.module.css";
import {
  FaPhone,
  FaFacebookF as Fb,
  FaInstagram as Insta,
  FaTwitter as Twitter,
  FaLinkedinIn as LinkedIn
} from "react-icons/fa";
import { MdMail as Mail } from "react-icons/md";

export default function SocialLinks() {
  return (
    <div>
      <div className={style.titleDiv}>
        <h2 className={styles.widgetTitle}>WE ARE EVERYWHERE</h2>
      </div>
      <div className={style.listDiv}>
        <ul className={style.lists}>
          <li className={style.list}>
            <div className={style.listItem}>
              <div className={style.iconDiv}>
                <FaPhone className={style.icon} />
              </div>
              <h1 className={style.iconText}>+92-326-4427063</h1>
            </div>
          </li>
          <li className={style.list}>
            <div className={style.listItem}>
              <div className={style.iconDiv}>
                <FaPhone className={style.icon} />
              </div>
              <h1 className={style.iconText}>+47-93-923-306</h1>
            </div>
          </li>
          <li className={style.list}>
            <div className={style.listItem}>
              <div className={style.iconDiv}>
                <Mail className={style.icon} />
              </div>
              <h1 className={style.iconText}>info@buggcy.com</h1>
            </div>
          </li>
        </ul>
      </div>
      <div className={style.socialLinks}>
        <h6 className={style.socialHeading}>FOLLOW US HERE</h6>
        <div className={style.socialLinksInner}>
          <div className={style.linkInnerMain}>
            <div className={style.singLinkDiv}>
              <a
                className={style.socialLink}
                href="https://www.facebook.com/buggcy"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Fb className={style.socialLinkIcon1} />
              </a>
            </div>
          </div>
          <div className={style.linkInnerMain}>
            <div className={style.singLinkDiv}>
              <a
                className={style.socialLink}
                href="https://twitter.com/buggcy_ltd"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter className={style.socialLinkIcon2} />
              </a>
            </div>
          </div>
          <div className={style.linkInnerMain}>
            <div className={style.singLinkDiv}>
              <a
                className={style.socialLink}
                href="https://www.linkedin.com/company/90591121"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedIn className={style.socialLinkIcon3} />
              </a>
            </div>
          </div>
          <div className={style.linkInnerMain}>
            <div className={style.singLinkDiv}>
              <a
                className={style.socialLink}
                href="https://www.instagram.com/buggcy_ltd/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Insta className={style.socialLinkIcon4} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
