import React, { useEffect } from "react";
import Main from "../../../Main/Main";
import Tabs from "../../Tabs/Tabs";
import Details from "../../Details/Details";
import DetailsData from "./DetailsDemandData";
function OnDemand({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="ON-DEMAND SERVICES"
        description="Buyers and sellers should connect quicker, better and wiser"
      />
      <Tabs active="0" />
      <Details data={DetailsData} />
    </div>
  );
}

export default OnDemand;
