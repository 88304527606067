import React, { useEffect } from "react";
import Main from "../../../Main/Main";
import Tabs from "../../Tabs/Tabs";
import Details from "../../Details/Details";
import DetailsData from "./DetailsEcomData";
function Ecom({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="E-COMMERCE"
        description="Build a shopping experience like no other"
      />
      <Tabs active="2" />
      <Details data={DetailsData} />
    </div>
  );
}

export default Ecom;
