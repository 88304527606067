import React from "react";
// import style from "./Tabs.module.css";
import "./Tabs.css";
import Tabs from "react-responsive-tabs";
import "react-responsive-tabs/styles.css";
import TabsContent from "./TabsData";
function getTabs() {
  return TabsContent.map((TabsContent, index) => ({
    title: TabsContent.name,
    getContent: () => TabsContent.data,
    key: index,
    tabClassName: " tabDiv ",
    panelClassName: "panel",
  }));
}

function IndustriesTabs({ active }) {
  const show = false;

  return (
    <div className="tabsContainer">
      <Tabs
        items={getTabs()}
        tabsWrapperClass="tabWrap"
        selectedTab="2"
        selectedTabKey={parseInt(active)}
        showMore={show}
      />
    </div>
  );
}

export default IndustriesTabs;
