const filterByCategory = (arr, category) => {
  return arr.filter(function (data) {
    return data.category === category;
  });
};

const filterPost = (arr, title) => {
  return arr.filter(function (data) {
    return data.url === title;
  });
};

export { filterPost };
export default filterByCategory;
