import React from "react";
import Card from "./Card/Card";
import style from "./ContentCards.module.css";

const ContentCards = ({ cardData }) => {
  return (
    <div className={style.cardsContainer}>
      {cardData.map(
        (data, index) => (
          <Card key={index} data={data} />
        ),
        []
      )}
    </div>
  );
};

export default ContentCards;
