import React from "react";
import style from "./Outsource.module.css";
import outsource from "../../../assets/img/outsource.jpg";
function Outsource() {
  return (
    <div className={style.mainContainer}>
      <div className={style.innerContainer}>
        <div className={style.leftContainer}>
          <p className={style.befHeading}>
            Professional Outsourcing Software Developers
          </p>
          <h1 className={style.heading}>
            Turning your digital dreams into reality.
          </h1>
          <p className={style.description}>
            Based in Oslo, Norway we at <strong>buggcy</strong> aim to grab the
            leading position as an outsourcing software development company. We
            develop innovative software solutions for enterprises located all
            across the globe, helping them grow their business by increasing
            their online presence. Our focus lies in, but we are not restricted
            to, designing, software engineering, and development solutions.
          </p>
          <p className={style.description}>
            For several years, we have been building software products using a
            team of designers and business people. The combined efforts of this
            team create a tech-savvy environment with an acute business sense.
            At buggcy, we work to be at the front of web-based business
            initiatives. Thereby, we offer one-stop-shop for all your web
            business needs.
          </p>
          <p className={style.description}>
            Our unmatched services give us the confidence to meet your
            objectives in solving technical challenges. This is how{" "}
            <strong> buggcy</strong> built its reputation as well; by providing
            innovative software solutions and second-to-none engineering and
            support services. We encourage you to refer to our clients if you
            wish to find out more about us.
          </p>
        </div>
        <div className={style.rightContainer}>
          <img src={outsource} alt="outSourceImg" className={style.fig}></img>
        </div>
      </div>
    </div>
  );
}

export default Outsource;
