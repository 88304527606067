import React, { useState } from "react";
import style from "../Blog.module.css";
import cx from "classnames";
import LinkGen from "../LinkGen";
function BlogSearch({ history }) {
  const [search, setSearch] = useState("");
  const submitForm = e => {
    e.preventDefault();
    history.push(`/search/${LinkGen(search)}`);
  };
  return (
    <>
      <form onSubmit={e => submitForm(e)}>
        <i className={cx("fas ", " fa-search", style.searchIcon)}></i>
        <input
          type="text"
          className={style.inputMain}
          placeholder="  Search Article"
          value={search}
          onChange={e => setSearch(e.target.value)}
        ></input>
      </form>
    </>
  );
}

export default BlogSearch;
