import React from "react";
const webDevData = [
  {
    toggle: {
      title: "Gain Efficiency Through Fintech Development",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "At <strong>buggcy</strong>, we work to create a solution for banks, exchanges and brokers to provide them with a saving on their online trading platforms, online business infrastructure and overall systems. We work using fintech development to reduce business cost and risk by providing experience and resource for proprietary platforms. Regardless if it is an online trading platform or a foreign exchange system, our custom fintech software development for desktop or mobile applications will enable you to assert presence in the competitive market."
    },
    key: "0"
  },
  {
    toggle: {
      title: "Quick Delivery Is the Key",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: (
        <>
          In this sector, one has to recognize how quickly things need to be to
          gain the most benefits. A highly competitive market means that
          business competition is running in full-strength. This, in turn, means
          that just waiting for software to kick-off is not feasible. Let this
          not be the case and choose a partner that understands this. With
          <strong>buggcy</strong>’s fintech development, benefits come from the
          incorporation of mechanisms, libraries and features that can
          kick-start your finance business, giving what you want, when you want.
          <br></br> We do this by effectively integrating agile methodology in
          our fintech software development. This is not just a way of work, it
          goes beyond. Agile development is a pillar upon which digital-first
          organizations work. With <strong>buggcy</strong>’s fintech
          development, all finance businesses can gain benefit through Agile
          methodology. Moreover, our work on fintech in the past ranges from
          implementing to optimizing Agile capabilities of our clients.
        </>
      )
    },
    key: "1"
  },
  {
    toggle: {
      title: "Fintech Development For All Businesses",

      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-blue.svg"
    },
    content: {
      para: "With <strong>buggcy</strong>’s fintech development, the size of your business does not matter. We have not only worked with early-stage start-ups, but also with established financial organizations. Hence, we prove that this form of development service is available to all stages of business. Our expert developers can help your business assess and understand customer opinions, and help you devise a digital plan so you can favourably reach your goals!"
    },
    key: "2"
  }
];

export default webDevData;
