import React from "react";
import style from "./Main.module.css";
export default function Main({ heading, description }) {
  return (
    <div className={style.mainContainer}>
      <div className={style.innerContainer}>
        <h1 className={style.heading}>{heading}</h1>
        <div className={style.description}>{description}</div>
      </div>
    </div>
  );
}
