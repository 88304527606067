import React, { useEffect } from "react";
import DataScrappingData from "./DataScrappingData";
import AccordComp from "../TempComponent/TempComponents";
import Main from "../../../Main/Main";
function DataScrapping({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="Web Development"
        description="Making Sure Your Business Reaches New Heights"
      />
      <AccordComp
        imgSrc="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/data-scraping.png"
        AccordData={DataScrappingData}
      />
    </div>
  );
}

export default DataScrapping;
