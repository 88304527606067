import React from "react";

const data = [
  {
    p1: {
      heading: "We Cater To All Businesses",
      description: (
        <>
          For consumers, all they are looking from an online platform is
          personalization, flexibility and a solution to their needs. If you are
          someone who is offering this, or perhaps even more, then welcome on
          board! Regardless of your target audience or business type, the
          service on-demand software that we will create for you will put you in
          a promising position, from order initiation to delivery.<br></br> We
          value the new businesses effectively looking for a trendy change that
          will improve their operations. On-demand services for businesses allow
          for a wider insight into operations and revenue management. From cash
          monitoring for CoD case scenarios to building online dashboards for
          business owners, all features are present to make online operations
          smooth. This online system allows for improvement in multiple ways,
          some of which are customer reviews, complaint notices, order
          cancellation and other reporting options. While all of this is also
          possible through manual, or typical, business operation, the fact that
          the service on-demand applications collect everything from a single
          site makes it more manageable.
        </>
      )
    },
    p2: {
      heading: "Scalable Solution Is The Way",
      description:
        "Trends regarding the service on-demand industry are looking great so far. As your business continues to grow there will come a time when even your software will need to expand and upgrade. A scalable model that fits your expanding business’s goals is what your aim should be; this is what we naturally work on."
    },
    imgSrc:
      "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/food2-1.png"
  }
];

export default data;
