const data = [
  {
    toggle: {
      title: "Data Scrapping",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "If you require accurate and reliable datasets with unlimited scale, select from our one-off subscription-based or enterprise services to get the best crawl engineers."
    },
    key: "0"
  },
  {
    toggle: {
      title: "Custom Tools To Extract Data Better",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-blue.svg"
    },
    content: {
      para: "<strong>buggcy</strong> provides custom tools that can scan the web and extract the exact information you need. With our tools, crawlers can swim through large quantities of data simultaneously while taking out only the useful data but at a faster rate."
    },
    key: "1"
  },
  {
    toggle: {
      title: "Quality Assurance Throughout",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: "We have built quality assurance checkpoints in all and every process because we value the time our clients give us. In return we, at <strong>buggcy</strong>, ensure that our web scraping tools function properly and give desired results."
    },
    key: "2"
  },
  {
    toggle: {
      title: "Providing Clean Data",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/disk-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/disk-blue.svg"
    },
    content: {
      para: "Oftentimes the data collected can turn out raw but not in the case of <strong>buggcy</strong>. We make sure that we first process the data that we collect using our custom tools. We pass it through several filters that take out incorrect, inaccurate and old data, and only presenting a precise need of our clients."
    },
    key: "3"
  }
];

export default data;
