import React from "react";
import style from "./Card.module.css";
export default function Card({ imgSrc, heading, description }) {
  return (
    <div className={style.pillarsInner}>
      <div className={style.figDiv}>
        <img alt="pillar" src={imgSrc} className={style.fig}></img>
      </div>
      <div className={style.detailsDiv}>
        <p className={style.heading}>{heading}</p>
        <p className={style.description}>{description}</p>
      </div>
    </div>
  );
}
