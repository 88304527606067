const data = [
  {
    toggle: {
      title: "Ensuring That Your Product Code Is Flawless",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "Agile development environment ensures that automated and manual testing of products give the results you desire, fulfilling your needs as a client. This methodology guides us to form the best practices for software quality assurance. Hence, we know exactly what is happening with your product. We consider the products that we make as our own and perform rigorous testing on them to gain satisfaction. Our quality assurance department believes nothing is error-free. Generating quality QA reports, ultimately, we are working to save your time, cost and lower stress on your applications."
    },
    key: "0"
  },
  {
    toggle: {
      title: "Automated Testing Services",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-blue.svg"
    },
    content: {
      para: "<strong>buggcy</strong> not only likes to showcase its brilliant set of programming solutions but also its quality assurance works. Our brilliant software quality assurance developers create unique and centred frameworks that not just create efficient testing procedures, but also automate resource-heavy tasks and facilitate continuous testing."
    },
    key: "1"
  },
  {
    toggle: {
      title: "Functionality and Smoke Tests",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: "Software quality assurance means that we use our friendly bugs to test the products we are creating for you. This gives us the confidence to check if we have designed everything as per your requirements. This approach is done through a series of test cases only so that we can check if the software is doing what it was intended to. As for the friendly bugs, our developers trace, locate and take them out giving you and us the peace of mind we deserve. For smoke testing, our QA experts adopt a different technique. Here, the QA runs through the entire application, touching all the major features of the product. This is to make sure that when test cases start, the developers have a clear run of the track till the very end. Hence, providing you with a speedy, yet reliable, resolutions to all or any issues."
    },
    key: "2"
  },
  {
    toggle: {
      title: "Manual Testing Services",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/disk-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/disk-blue.svg"
    },
    content: {
      para: "We offer manual testing procedures because we cater to all sorts of clients and their needs. With manual testing, our software quality assurance team consists of experienced and responsible engineers who have years of experience in catching bugs. We consider it our responsibility to make sure that every software code is impeccable!"
    },
    key: "3"
  },
  {
    toggle: {
      title: "Why Choose Us?",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-blue.svg"
    },
    content: {
      para: "When we are working on your product to test the strength of its performance, we do so just to be completely transparent about what we have created. At <strong>buggcy</strong>, we develop a transparent, communicative and collaborative approach with our clients that goes a long way, well into after-sales. Our cross-functional team lift our abilities further up into the sky as we all are ready to deploy and showcase our proven track record. With a dedicated in-house SQA team, quality is guaranteed!"
    },
    key: "4"
  }
];

export default data;
