import React, { useEffect } from "react";
import * as actions from "./homeImp";
export default function Home({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [title]);
  return (
    <div>
      <actions.Main />
      <actions.Mission />
      <actions.Service />
      <actions.ToolTech />
      {/* <actions.Portfolio /> */}
      <actions.Expertise />
      {/* <actions.Testimonials /> */}
      {/* <actions.Clients /> */}
    </div>
  );
}
