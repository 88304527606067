import React from "react";
const data = [
  {
    toggle: {
      title: "New Possibilities for Mobile Applications",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "Our latest QA testing methods and out-of-box designs ensure that your mobile application is more like a sweet experience than just a project. Looking at the wide range of categories offered in the App stores of mobile operating software, there is no limit to your creativity and ideas. Similarly, we believe the sky is the limit when offering our services in mobile application development. Whether you require a mobile app in the field of healthcare, on-demand services, education, finance, eCommerce, retail, or hospitality <strong>buggcy</strong> has got you covered in Android, iOS or Windows."
    },
    key: "0"
  },
  {
    toggle: {
      title: "iOS, Android or Windows",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-blue.svg"
    },
    content: {
      para: "Our experience in the field of iOS mobile application development enables us to create a ground-breaking app for your business needs. Whether you are looking for a feature-rich solution or a clean yet purposeful design, we make sure that you receive the best user experience always. Hence, use our iOS app development services to maximise your brand loyalty and increase your business revenue. We design attractive, smooth, and reliable applications that can get you the attention you need from your audience."
    },
    key: "1"
  },
  {
    toggle: {
      title: "Cross-Platform Development",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: (
        <>
          In this highly competitive market of mobile software, mobile
          applications that run on multiple systems yield the best benefits. We
          make use of open source and standard web technologies like JS, CSS3
          and HTML5 to make apps function and look the same way on all major
          platforms. <br></br>
          <strong>buggcy</strong>’s app innovation team has years of experience
          developing audience-specific applications for mobile and handheld
          devices. We know how to enhance the user experience for apps in
          devices like iPhone, iPad, Android phones and tablets, and even
          windows device. We utilize Google’s Android SDK to develop innovative
          and out-of-box applications that promote creativity
        </>
      )
    },
    key: "2"
  },
  {
    toggle: {
      title: "We Stand Out from the Rest",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/disk-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/disk-blue.svg"
    },
    content: {
      para: "Regardless if you want to go for a standard application or one that is unique, thoroughly thought out and state-of-the-art for your business, <strong>buggcy</strong> ensures that you receive unparalleled service. Our diverse industry experience guarantees reliability and we love to showcase it. Here, we understand the challenges that you have to face to stay ahead of the business race you are in and recognize your company mission, vision, and values. That is why we aim to bring your ideas to life through our full-spectrum mobile application services. We are proud of our industry experience that spans across the entire globe through international clientele in the regions of Middle East, Europe, South Asia and North America."
    },
    key: "3"
  },
  {
    toggle: {
      title: "Cost-Effective Solutions",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-blue.svg"
    },
    content: {
      para: (
        <>
          A dedicated web development team, combined with strong business acumen
          and project management skills ensure that you get the best experience
          at the end. <strong>buggcy</strong> caters to all kinds of web
          development service, whether you require an online web app development
          for internal usage or your audience and customers. Our highly
          qualified team will take your ideas as their own to give the best of
          results.<br></br>
          <br></br> We do not give you jibber-jabber answers but get right down
          to business. We like to exercise wide communication, productivity and
          value of time, ensuring efficient delivery that guarantees the results
          you want. Getting our mobile application services means that you are
          guaranteeing yourself a place in the competitive market at a
          reasonable cost.
        </>
      )
    },
    key: "4"
  }
];

export default data;
