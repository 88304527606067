import React, { useEffect } from "react";
import MobileAppData from "./MobileAppData";
import AccordComp from "../TempComponent/TempComponents";
import Main from "../../../Main/Main";
function MobileApp({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="MOBILE APPLICATION"
        description="New Possibilities for Mobile Applications"
      />
      <AccordComp
        imgSrc="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/mobile.png"
        AccordData={MobileAppData}
      />
    </div>
  );
}

export default MobileApp;
