import React from "react";
import cx from "classnames";
import Card from "./Card/Card";
import style from "./Services.module.css";
import { Link } from "react-router-dom";
import web from "../../../assets/img/website.png";
import mobile from "../../../assets/img/mobile.png";
import crypto from "../../../assets/img/crypto.png";
import scrapping from "../../../assets/img/datascience.png";
import science from "../../../assets/img/datascrapping.png";
import enterprise from "../../../assets/img/entrprise.png";
import management from "../../../assets/img/product.png";
import ui from "../../../assets/img/ui.png";
import devops from "../../../assets/img/devops.png";

export default function Servies() {
  const description =
    " Our focus areas of service cover a wide area of regions in software and web development. From Big Data to DevOps to simple software application for your phone, buggcy has you covered!";
  const initState = [
    {
      img: web,
      heading: "Web Development",
      description:
        "From Frontend to Backend to even Full-stack development, we fast-track your project delivery to create an experience beyond you expectations."
    },
    {
      img: mobile,
      heading: "Mobile Applications",
      description:
        "Our latest QA testing methods and Out-of-Box designs ensure that your mobile application is more like a sweet experience than just a project."
    },
    {
      img: crypto,
      heading: "Cryptography & IoT",
      description:
        "We offer cryptography-based security solutions, and IoT consultancy to businesses across a wide range of sectors just so you can excel in providing secured IoT products."
    },
    {
      img: science,
      heading: "Data Scraping",
      description:
        "If you require accurate and reliable datasets with unlimited scale, you need our one-off subscription of data scraping. Get the best budget-friendly crawl engineers with buggcy"
    },
    {
      img: scrapping,
      heading: "Big Data & Data Science",
      description:
        "Multiple streams of structured and unstructured data won’t get organized unless you hire professionals who are experts in analysing data and identifying key metrics."
    },
    {
      img: ui,
      heading: "Branding & UI/UX Designing",
      description:
        "Save time and money by allowing us to build unique interactive features that will take your UI/UX design experience to a whole new level."
    },
    {
      img: enterprise,
      heading: "Enterprise Software Development",
      description:
        "Proudly serving tech leaders and businesses our turnkey solutions enable us to design software that best fits your enterprise ecosystem. "
    },
    {
      img: devops,
      heading: "Dev-Ops",
      description:
        "Development and operation teams must be in sync if efficiency is required. Our DevOps engineers understand this notion to bring your accelerated project delivery."
    },
    {
      img: management,
      heading: "Product Management",
      description:
        "Our technical proficiency and business acumen enable us to develop your product and deliver it on time because we strive for a great customer relationship."
    }
  ];

  const cardData = initState;
  return (
    <div className={style.mainContainer}>
      <div className={style.innerContainer}>
        <div className={cx(style.oneCard, style.mainText)} id="text">
          <section className={style.textDesc}>
            <h2 className={style.descHeading}>
              OUR <strong>FOCUSED</strong> SERVICES
            </h2>
            <p className={style.descDesc}>{description}</p>
            <p className={style.descBtn}>
              <Link to="/contact-us">
                <button className={style.talkBtn}>Find out More</button>
              </Link>{" "}
            </p>
          </section>
        </div>
        {cardData.map((data, index) => (
          <div key={index} className={style.oneCard}>
            <Card
              imgSrc={data.img}
              heading={data.heading}
              description={data.description}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
