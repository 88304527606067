import React from "react";
import style from "./Main.module.css";
import { Link } from "react-router-dom";
export default function Main() {
  return (
    <div className={style.mainContainer}>
      <div className={style.innerContainer}>
        <section className={style.introductionSlogan}>
          <h1 className={style.sloganText}>
            TURNING YOUR <strong>DIGITAL DREAMS</strong> INTO{" "}
            <strong>REALITY.</strong>
            <br></br>Providing{" "}
            <strong>high-quality, reliable, and scalable</strong> software
            solutions
          </h1>
        </section>

        <section className={style.introSectionDesc}>
          <p className={style.descriptionText}>
            Buggcy is Designing and developing custom software solutions for
            businesses of all types.
            <br></br>We're committed to delivering innovative and high-quality
            products
          </p>
          <Link to="/contact-us">
            <button className={style.talkBtn}>Let's Talk</button>
          </Link>
        </section>
      </div>
    </div>
  );
}
