import React, { useContext } from "react";
import styles from "./Accordions.module.css";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import AccordionContext from "react-bootstrap/AccordionContext";
import "./Accordions.css";
function ContextAwareToggle({
  children,
  eventKey,
  callback,
  imgBef,
  imgAft,
  background,
}) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      className={`toggleBtn ${background} ${
        isCurrentEventKey ? "activeToggle" : ""
      }`}
    >
      {isCurrentEventKey ? (
        <>
          <img src={imgAft} className="imgFig" alt=""></img>
        </>
      ) : (
        <>
          <img src={imgBef} className="imgFig" alt=""></img>
        </>
      )}
      {children}
    </button>
  );
}
export default function Accordions({ data, backgroundClass = "grey" }) {
  return (
    <div
      className={`${styles.accordionsDiv} ${
        backgroundClass === "grey" ? " grey" : " white"
      }`}
    >
      <Accordion defaultActiveKey="0">
        {data.map(
          (singCard, index) => (
            <Card key={index} className="mainCard">
              <ContextAwareToggle
                eventKey={singCard.key}
                imgBef={singCard.toggle.imgBef}
                imgAft={singCard.toggle.imgAft}
                background={backgroundClass}
              >
                {singCard.toggle.title}
              </ContextAwareToggle>
              <Accordion.Collapse
                eventKey={singCard.key}
                className={`accordContent ${backgroundClass}`}
              >
                <Card.Body className="cardContent">
                  <p className="cardContentText">{singCard.content.para}</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ),
          []
        )}
      </Accordion>
    </div>
  );
}
