import React from "react";
import "./Tabs.css";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import data from "./TabsContent/ContentData";
import TabsPillsContent from "./TabsContent/TabsContent";
function Tabs() {
  return (
    <div className="mainContainer">
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="first"
        transition={false}
      >
        <Row className="rowContainer">
          <Col md={3}>
            <Nav variant="pills" className="flex-column" id="nav">
              {data.map(
                (data, index) => (
                  <Nav.Item key={index} id="tabsButton">
                    <Nav.Link eventKey={data.activeKey} className="navLinkTab">
                      <span className="tabButton">
                        <img
                          alt="title"
                          src={data.listContent.imgSrc}
                          className="headingImg"
                        ></img>
                        <span className="tabButtonText">
                          {data.listContent.title}
                        </span>
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                ),
                []
              )}
            </Nav>
          </Col>
          <Col md={9} className="tabContentDiv">
            <Tab.Content>
              {data.map(
                (data, index) => (
                  <Tab.Pane key={index} eventKey={data.activeKey}>
                    <TabsPillsContent data={data.tabContent}></TabsPillsContent>
                  </Tab.Pane>
                ),
                []
              )}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}

export default Tabs;
