import React, { useEffect } from "react";
import style from "./Contact.module.css";
import Main from "../Main/Main";
import Form from "./Form/Form";
import Details from "./Details/Details";
// import Map from "./Map/Map";
export default function Contact({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="Contact Us"
        description="Want to get in touch? We’d love to hear from you"
      />
      <div className={style.mainFormContanier}>
        <div className={style.formDetailsContainer}>
          <Details />
          <Form />
        </div>
      </div>
      {/* <Map /> */}
    </div>
  );
}
