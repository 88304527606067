import React from "react";
import style from "./Details.module.css";
export default function Details({ data }) {
  return (
    <div className={style.mainContainer}>
      <div className={style.textContainer}>
        <h3 className={style.heading}>{data[0].p1.heading}</h3>
        <p className={style.description}>{data[0].p1.description}</p>
        <h3 className={style.heading}>{data[0].p2.heading}</h3>
        <p className={style.description}>{data[0].p2.description}</p>
      </div>
      <div className={style.imageContainer}>
        <img src={data[0].imgSrc} className={style.fig} alt=""></img>
      </div>
    </div>
  );
}
