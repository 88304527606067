const data = [
  {
    toggle: {
      title: "Product Management",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "Our technical proficiency and business acumen enable us to develop your product and deliver it on time because we strive for a great customer relationship."
    },
    key: "0"
  },
  {
    toggle: {
      title: "Brilliant Product UX Team",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-blue.svg"
    },
    content: {
      para: "At <strong>buggcy</strong> we understand how much the UXD contributes in letting people navigate an online platform. We house a brilliant set of developers and designers that can experience through research and feedback, which enables them to understand the product."
    },
    key: "1"
  },
  {
    toggle: {
      title: "Impressive Minimum Viable Product",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: "We house a team that consists of brilliant software architects, analysts, and testers that translate business vision into technology features. An MVP strategy which defines product scope and client organization is then created."
    },
    key: "2"
  },
  {
    toggle: {
      title: "Testing of Products",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/disk-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/disk-blue.svg"
    },
    content: {
      para: "At <strong>buggcy</strong>, we make sure that nothing goes past us without being put into hectic testing procedures. The Quality Assurance team ensures that when a product hits the market, it functions exactly the way we designed it, with complete functionalities and performance."
    },
    key: "3"
  },
  {
    toggle: {
      title: "",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-blue.svg"
    },
    content: {
      para: ""
    },
    key: "4"
  }
];

export default data;
