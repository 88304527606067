import React from "react";
import style from "./ToolTech.module.css";
import cx from "classnames";
import { FaArrowRight } from "react-icons/fa";

import { Link } from "react-router-dom";
import tooltech from "../../../assets/img/tech-stacks.png";
function ToolTech() {
  return (
    <div className={style.mainContainer}>
      <div className={style.innerContainer}>
        <div className={cx(style.container1, style.container2)}>
          <h1 className={style.heading}>
            TOOLS & <strong>TECHNOLOGIES</strong>
          </h1>
          <p className={style.headingDesc}>
            We Work Passionately With Cutting-Edge <br></br>Technologies
          </p>
          <p className={style.description}>
            Technology is constantly evolving and so should we. In this
            industry, new, interesting and unique challenges are coming which
            require modern solutions. Expert individuals who understand your
            goals and work with dedication are who you need. In short, a
            wholesome team using the right technology for the right job.{" "}
            <strong>buggcy </strong> offers maturity through years of experience
            and a team of bright and boundless engineers and developers who work
            to see your goals to the end.
          </p>
          <p className={style.description}>
            We operate cross-functional teams that can efficiently bring you a
            well-organized and precise execution of your project using
            technologies we have become expert in. We are at the top of our
            game, and this is what you deserve!
          </p>
          <p className={style.btnP}>
            <Link to="/contact-us">
              <button className={style.talkBtn}>
                Let's Talk
                <FaArrowRight color="#227ee2" className={style.arrow} />
              </button>
            </Link>
          </p>
        </div>
        <div className={style.container1}>
          <div className={style.container1Inner}>
            <img
              alt="ToolTech img"
              src={tooltech}
              className={style.figure}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ToolTech;
