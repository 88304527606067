import React, { useState, useEffect, useCallback } from "react";
import jobsData from "./JobsData";
import style from "./JobsPanel.module.css";
function Filter({ listData, setListData, searchData, setSearchData }) {
  const [filters, setFilters] = useState({
    freelance: true,
    temporary: true,
    partTime: true,
    fullTime: true,
    internship: true,
  });
  const [search, setSearch] = useState({
    keywords: "",
    location: "",
  });
  const searchForFilter = useCallback(
    arr => {
      const data = [...arr];
      const searched = data.filter(data => {
        return (
          (data.title.toLowerCase().includes(search.keywords.toLowerCase()) &&
            data.city.toLowerCase().includes(search.location.toLowerCase())) ===
          true
        );

        // if (search.location !== "") {
        //   if ()
        //     return data;
        // }
      });

      return searched;
    },
    [search.keywords, search.location]
  );
  const filterData = useCallback(
    (arr, filters) => {
      const data = searchForFilter(arr);

      const filtered = data.filter(data => {
        return (
          (data.freelance && filters.freelance) ||
          (data.temporary && filters.temporary) ||
          (data.partTime && filters.partTime) ||
          (data.fullTime && filters.fullTime) ||
          (data.freelance && filters.freelance)
        );

        // if (data.freelance) {
        //   if (filters.freelance) {
        //     return data;
        //   }
        // }
        // if (data.temporary) {
        //   if (filters.temporary) {
        //     return data;
        //   }
        // }
        // if (data.partTime) {
        //   if (filters.partTime) {
        //     return data;
        //   }
        // }
        // if (data.fullTime) {
        //   if (filters.fullTime) {
        //     return data;
        //   }
        // }
        // if (data.internship) {
        //   if (filters.internship) {
        //     return data;
        //   }
        // }
      });
      setListData(filtered);
      return filtered;
    },
    [setListData, searchForFilter]
  );
  useEffect(() => {
    filterData(searchData, filters);
  }, [filters, filterData, searchData]);

  const submitSearch = e => {
    e.preventDefault();
    searchItems(jobsData, filters);
  };

  const searchItems = (arr, filters) => {
    const data = filterData(arr, filters);

    const searched = searchForFilter(data);

    setListData(searched);

    // setSearchedData(searched);
  };

  const inputChange = e => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const handleChange = e => {
    setFilters({ ...filters, [e.target.name]: !filters[e.target.name] });
  };

  return (
    <>
      <div className={style.searchDiv}>
        <form onSubmit={e => submitSearch(e)}>
          <input
            type="text"
            value={search.keywords}
            placeholder="Keywords"
            className={style.input}
            onChange={e => inputChange(e)}
            name="keywords"
          ></input>
          <input
            type="text"
            value={search.location}
            placeholder="Location"
            className={style.input}
            onChange={e => inputChange(e)}
            name="location"
          ></input>
          <button type="submit" className={style.submit}>
            Search Jobs
          </button>
        </form>
      </div>
      <ul className={style.filterList}>
        <li className={style.singleFilter}>
          <label className={style.labelFilter}>
            <input
              type="checkbox"
              name="freelance"
              checked={filters.freelance}
              onChange={e => handleChange(e)}
              className={style.filterCheck}
            />
            FreeLance
          </label>
        </li>
        <li className={style.singleFilter}>
          <label className={style.labelFilter}>
            <input
              type="checkbox"
              name="fullTime"
              checked={filters.fullTime}
              onChange={e => handleChange(e)}
              className={style.filterCheck}
            />
            Full Time
          </label>
        </li>
        <li className={style.singleFilter}>
          <label className={style.labelFilter}>
            <input
              type="checkbox"
              name="internship"
              checked={filters.internship}
              onChange={e => handleChange(e)}
              className={style.filterCheck}
            />
            InternShip
          </label>
        </li>
        <li className={style.singleFilter}>
          <label className={style.labelFilter}>
            <input
              type="checkbox"
              name="partTime"
              checked={filters.partTime}
              onChange={e => handleChange(e)}
              className={style.filterCheck}
            />
            Part Time
          </label>
        </li>
        <li className={style.singleFilter}>
          <label className={style.labelFilter}>
            <input
              type="checkbox"
              name="temporary"
              checked={filters.temporary}
              onChange={e => handleChange(e)}
              className={style.filterCheck}
            />
            Temporary
          </label>
        </li>
      </ul>
    </>
  );
}

export default Filter;
