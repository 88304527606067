const data = [
  {
    p1: {
      heading: "Open Source Ecommerce Website Development",
      description:
        "Open-source e-commerce development platforms make it simple for everybody to sell products online and still manage a professional online presence. Their configuration is easy and has system features that include all basics so you don’t have to worry about customization. For <strong>buggcy</strong>, our eCommerce website development effectiveness lies in several of these platforms. Since we are not restricted to only Magento, Woo Commerce, or Shopify, we are an eCommerce development company that can offer complete solutions."
    },
    p2: {
      heading: "Adding Convenience to Your Business",
      description:
        "Our belief in building fast and reliable products for clients has helped transform the eCommerce website development industry. Through our e-commerce development services, buyers and sellers interact in a much convenient manner. Modern world dictates that consumers ask for speed of delivery and a quick scan-through of products. Through our impressive web development solutions, you will be mitigating the technical aspects that restrict you to outperform your competitions. With a strong and complete capability to roll out mobile and desktop platforms for your online business, we, as an eCommerce development company make sure that you get a sharp edge over your competition. This is what our clients deserve."
    },
    imgSrc:
      "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/ecom2-1.png"
  }
];

export default data;
