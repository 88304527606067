import React, { useEffect } from "react";
import Main from "../../../Main/Main";
import Tabs from "../../Tabs/Tabs";
import Details from "../../Details/Details";
import DetailsData from "./DetailsHealthData";
function Health({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="HEALTHCARE"
        description="Healthcare today for a healthier tomorrow"
      />
      <Tabs active="1" />
      <Details data={DetailsData} />
    </div>
  );
}

export default Health;
