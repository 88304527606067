import React from "react";
const webDevData = [
  {
    toggle: {
      title: "Create A Brighter Tomorrow Through E-Learning",

      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: (
        <>
          <strong>buggcy</strong> has worked on some amazing and out-of-box
          education software development projects to create solutions to distant
          learning. Today’s e-learning industry aims to reduce the distance
          between a student and their education. By successfully bridging the
          gap between a teacher and the student we, at <strong>buggcy</strong>,
          have come up with innovative ideas to modern education.<br></br>
          <br></br>We believe in this cause and have already punched in our
          experience to make sure that we stay true to our goal, which is
          innovation. Signing up with <strong>buggcy</strong> means getting a
          great user experience, coupled with revolutionizing educational
          system, and fulfilling objectives of higher education. Our application
          development procedures meet all the necessary checklist and lift
          e-learning to a higher stage.
        </>
      )
    },
    key: "0"
  },
  {
    toggle: {
      title: "Education Software Development Offers Digital Publishing",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: (
        <>
          With mobile access almost everywhere around us, so are the digital
          publishing platforms. Exponential growth in digitization is bringing
          physical publishing to a decline. Each digital learning platform
          focusses on great user experience so that goal achievement becomes
          regular business experience a successful marketing strategy.
          Thankfully, we are aware of the functionalities like sharing,
          statistic generation, security and interactive elements to give our
          clients the edge they are looking for. <br></br>Our education software
          development service offers a chance to rid students the burden of
          carrying weighty books and integrate all coursebooks in the cloud.
          Through this, we are not only adding value to education but also
          making sure that all students can focus on the task at hand. With less
          distraction and an active mind, learning becomes easier.
        </>
      )
    },
    key: "1"
  },
  {
    toggle: {
      title: "A Brilliant Learning Management System",

      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-blue.svg"
    },
    content: {
      para: (
        <>
          <strong>buggcy</strong> develops unique Learning Management Systems
          that cater to the needs of a typical student through an excellent User
          Interface (UI). We offer education software development solutions that
          produce a fitting User Experience (UX). Through our insightful
          techniques towards better learning, we are only making sure that
          students stay focussed and engaged with their studies.<br></br> We are
          always on top of the growing and changing trends of the world. This is
          why we also provide a web-based platform with a native mobile
          application for the LMS. This will cater to the requirements of all
          types of phone operating software. Regardless if they are iOS, Android
          or even Windows, our education and e-learning applications are
          top-notch and we are prepared to showcase our skills.
        </>
      )
    },
    key: "2"
  }
];

export default webDevData;
