import React from "react";
import style from "./Footer.module.css";
export default function Footer() {
  return (
    <div className={style.mainContainer}>
      <div className={style.innerContainer}>
        <div className={style.leftContainer}>
          <p className={style.footerText}>
            ©2020 All Rights Reserved By <strong>buggcy</strong>
          </p>
        </div>
        <div className={style.rightContainer}>
          <a
            href="https://buggcy.com/privacy-policy/"
            className={style.footerLink}
          >
            Privacy Policy
          </a>
          <a
            href="https://buggcy.com/terms-conditions/"
            className={style.footerLink}
          >
            Terms & Conditions
          </a>
        </div>
      </div>
    </div>
  );
}
