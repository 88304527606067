import React from "react";
import * as displays from "./footerImp";

import style from "./main.module.css";
export default function main() {
  return (
    <div className={style.mainContainer}>
      <div className={style.contactContainer}>
        <displays.Contact />
      </div>
      <div className={style.widgetContainer}>
        <displays.Widget />
      </div>
      <div className={style.widgetContainer}>
        <displays.FooterBar />
      </div>
    </div>
  );
}
