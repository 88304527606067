import React from "react";
import style from "./Card.module.css";
import { Link } from "react-router-dom";
import { FaUserAlt as User, FaClock as Clock } from "react-icons/fa";
import linkGen from "../../LinkGen";
function Card({ data }) {
  return (
    <div className={style.cardWrapper}>
      <div className={style.imageContainer}>
        <Link to={`/blog/${data.category}/${linkGen(data.title)}`}>
          <img src={data.imgSrc} className={style.fig} alt=""></img>
        </Link>
      </div>
      <div className={style.detailsContainer}>
        <Link
          to={`/blog/${data.category}/${linkGen(data.title)}`}
          className={style.heading}
        >
          {data.title}
        </Link>
        <div className={style.detailDiv}>
          <div className={style.date}>
            <Clock className={style.icon} />
            {data.date}
          </div>
          <div className={style.author}>
            <User className={style.icon} />
            {data.author}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
