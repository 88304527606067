import React from "react";

const data = [
  {
    p1: {
      heading: "We Provide Development Possibilities",
      description: (
        <>
          <p>
            <strong>buggcy</strong> hosts teams of developers that expert in
            fintech development. We not only design but also create accounting
            software with unparalleled APIs that can flawlessly track inventory
            and manage daily cash flows. Banks, insurance companies, blockchain
            users and the entire field of finance should strive to provide an
            ultimate customer experience. This is possible through a
            consumer-centred mobile or web application that these finance
            businesses can use. With <strong>buggcy</strong>, you can fast-track
            your growth through this idea by implementing the following features
            into your online app
          </p>
          <ul style={{ textAlign: "center" }}>
            <li>Vendor Database management</li>
            <li>Cash Flow Management</li>
            <li>Financial Planning</li>
            <li>Invoicing and Account Receivable Designs</li>
            <li>Risk Analysis</li>
            <li>Investment Analysis</li>
            <li>And much more</li>
          </ul>
        </>
      )
    },
    p2: {
      heading: "",
      description:
        "Businesses need to choose the right fintech development company as it can very well predetermine the success of their project. buggcy recommends that before you sign up for any kind of deal, prepare a detailed Terms of Reference. This should include the agreed discussion on reference designs, finalized terms & conditions, development, internal testing, and commissioning. Work with buggcy to transform your business and create a better industry for others. The world of finance is changing rapidly; from retail banking to insurance to merchant services. Find out more about how we can serve you better through our expertise in fintech software development."
    },
    imgSrc:
      "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/fintech2-1.png"
  }
];

export default data;
