import React from "react";
import style from "./SearchPage.module.css";
import Search from "./BlogSearch";
import Main from "../../Main/Main";
import ContentCard from "../ContentCards/ContentCards";
import BlogData from "../BlogData";
function SearchPage({ match, history }) {
  const searchText = match.params.id.split("-").join(" ");

  const filterData = data => {
    return data.title.toLowerCase().includes(searchText.toLowerCase()) === true;
  };
  const searchData = () => {
    return BlogData.filter(filterData);
  };

  const cardData = searchData();
  return (
    <div>
      <Main
        heading="SEARCH RESULT"
        description={<Search history={history} />}
      />
      <h1 className={style.searchData}>{`SEARCH RESULT FOR: ${searchText}`}</h1>
      <ContentCard cardData={cardData} />
    </div>
  );
}

export default SearchPage;
