const data = [
  {
    toggle: {
      title: "Firm Software Foundation for Businesses",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "At <strong>buggcy</strong>, our expertise in the wide spectrum of the technology enables us to skilfully provide services in enterprise software development. From providing modifications to an existing enterprise software application to building an entirely new web or mobile app from the ground up, we serve all sorts of clients in this area. We are proudly serving tech leaders and businesses making their mark in the market using innovation and technology. When it comes being an enterprise software development company, we understand what are its requirements. From planning to thinking strategies to creating architecture and forming codes, we make sure that whatever we are doing has a sense of out-of-the-box touch to it, giving you the best solutions on technical and business grounds."
    },
    key: "0"
  },
  {
    toggle: {
      title: "Systematic Enterprise Software Development",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-blue.svg"
    },
    content: {
      para: "A well-structured enterprise software development company has a systematic process to follow which makes it an expert in the niche. Through a constant system of quality control and feedbacks, we streamline your product delivery. Our developers and tech experts go through your business in a process we call Business Analysis. We find optimal solutions to our clients’ troubles and present them for approval. We then work on Engineering Needs to cover all specifications and validations to make sure that our enterprise software solution meets your business needs. Finally, our talent in Design works to provide you with incredible user experience, something you would not expect in an enterprise software design"
    },
    key: "1"
  },
  {
    toggle: {
      title: "Custom Enterprise Software Solutions",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: "<strong>buggcy</strong> considers all possibilities of solutions for an enterprise looking for support software. In this modern stage, a business might be running at half its potential if it not looking to integrate with enterprise software development. We believe in solutions that are scalable and customizable since all enterprises are aiming to grow when they choose us. Therefore, let us help you to grab the opportunity of the best solutions to your organizations that can improve key facets and automatize employee collaboration."
    },
    key: "2"
  },
  {
    toggle: {
      title: "Complete Integration of Software",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/disk-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/disk-blue.svg"
    },
    content: {
      para: "In enterprise software development it is generally a good plan if you are choosing to go for a solution that can offer a well-coordinated SaaS and support to third-party apps. At <strong>buggcy</strong>, we help to improve online infrastructure by providing reliable APIs and excellent microservices. Launching your latest mobile strategy becomes so much easier when you know you have built a comprehensive enterprise application that is fitting all your needs."
    },
    key: "3"
  },
  {
    toggle: {
      title: "Cost-Effective Solution",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/thumb-blue.svg"
    },
    content: {
      para: "We house all the necessary team members that can bring all ideas to life. By providing accessibility and strategic feedback we ensure efficient delivery of services, complete productivity and cost-efficient projects that benefit everyone. Our enterprise software development solution is free from any unusable and ‘clunky’ features. We do not believe in providing a solution in a maze, rather keep things clean, intact and touchable."
    },
    key: "4"
  }
];

export default data;
