import React from "react";
import style from "./why.module.css";
import bannerImg from "../../../assets/img/banner-img1.png";
export default function Mission() {
  const missionStatement = `As a cutting-edge software development business, we specialise in creating effective digital products and experiences. Our connection with clients sets us apart from competitors in addition to our expertise. Collaboration across departmental lines and ongoing development define our strategy. Because we care about our customers, they not only get a team of highly skilled developers, but also the assistance and advice of accomplished engineers and business experts.`;

  return (
    <div className={style.mainContainer}>
      <div className={style.textContainer}>
        <section className={style.heading}>
          <span>
            WHY <strong className={style.missionText}>buggcy</strong>
          </span>
        </section>
        <section className={style.description}>
          <span className={style.descriptText}>{missionStatement}</span>
        </section>
      </div>
      <div className={style.imageContainer}>
        <img
          alt="ILLUSTRATION"
          src={bannerImg}
          className={style.imgStyle}
        ></img>
      </div>
    </div>
  );
}
