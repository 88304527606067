import React from "react";
import styles from "../Widget.module.css";
import style from "./Global.module.css";
export default function GlobalHead() {
  return (
    <div>
      <div className={style.titleDiv}>
        <h2 className={styles.widgetTitle}>OUR GLOBAL PRESENCE</h2>
      </div>
      <div className={style.mainDiv}>
        <div className={style.mainInner}>
          <div className={style.textContainer}>
            <p className={style.heading}>NORWAY</p>
            <p className={style.description}>
              Beryllvegen 98,9022 Oslo, Norway
            </p>
            <p className={style.footer}>Phone: +47-93-923-306</p>
          </div>
        </div>

        <div className={style.mainInner}>
          <div className={style.textContainer}>
            <p className={style.heading}>PAKISTAN</p>
            <p className={style.description}>
              Liberty Plaza, Gulberg, Lahore, Pakistan
            </p>
            <p className={style.footer}>Phone: +92-326-4427063</p>
          </div>
        </div>
      </div>
    </div>
  );
}
