import React, { useEffect } from "react";
import IotData from "./IotData";
import AccordComp from "../TempComponent/TempComponents";
import Main from "../../../Main/Main";
function Iot({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="CRYPTOGRAPHY & IOT"
        description="Adding Confidence to the Internet of Things & Much More"
      />
      <AccordComp
        imgSrc="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/iot.png"
        AccordData={IotData}
      />
    </div>
  );
}

export default Iot;
