import React from "react";
import style from "./TempComponent.module.css";
import Accordions from "../../../Accordions/Accordions";
function TempComponents({ imgSrc, AccordData, backgroundClass = "grey" }) {
  return (
    <div className={style.mainContainer}>
      <div className={style.imgContainer}>
        <img src={imgSrc} alt="" className={style.fig}></img>
      </div>
      <Accordions data={AccordData} backgroundClass={backgroundClass} />
    </div>
  );
}

export default TempComponents;
