import React from "react";
import style from "./EngageModel.module.css";
export default function EngageModel() {
  return (
    <div className={style.mainContainer}>
      <div className={style.headingDiv}>
        <h1 className={style.heading}>
          <strong>Engagement </strong>Models
        </h1>
        <p className={style.description}>
          We work closely to your requirements, building Apps that can:<br></br>{" "}
          Boost your profitability. Get you noticed. Put you ahead of your
          rivals.
        </p>
      </div>
      <div className={style.cardContainer}>
        <div className={style.singleCard}>
          <div className={style.topImg}>
            <img
              src="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/Icon-6.png"
              alt=""
              className={style.topFig}
            ></img>
          </div>
          <div className={style.centeredImg}>
            <img
              src="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/full-time-min.png"
              alt=""
              className={style.centerFig}
            ></img>
          </div>
          <ul className={style.listMenu}>
            <li className={style.listItem}>
              <span className={style.listText}>8 hours per day</span>
            </li>
            <li className={style.listItem}>
              <span className={style.listText}>5 days a week</span>
            </li>
            <li className={style.listItem}>
              <span className={style.listText}>Dedicated Resource</span>
            </li>
            <li className={style.listItem}>
              <span className={style.listText}>One week free trial</span>
            </li>
            <li className={style.listItem}>
              <span className={style.listText}>
                <em>
                  2 weeks notice provided if you wish to discontinue the service
                </em>
              </span>
            </li>
          </ul>
        </div>
        <div className={style.singleCard}>
          <div className={style.topImg}>
            <img
              src="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/Icon-6.png"
              alt=""
              className={style.topFig}
            ></img>
          </div>
          <div className={style.centeredImg}>
            <img
              src="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/part-time-min.png"
              alt=""
              className={style.centerFig}
            ></img>
          </div>
          <ul className={style.listMenu}>
            <li className={style.listItem}>
              <span className={style.listText}>4 hours per day</span>
            </li>
            <li className={style.listItem}>
              <span className={style.listText}>5 days a week</span>
            </li>
            <li className={style.listItem}>
              <span className={style.listText}>Dedicated Resource</span>
            </li>
            <li className={style.listItem}>
              <span className={style.listText}>One week free trial</span>
            </li>
            <li className={style.listItem}>
              <span className={style.listText}>
                <em>
                  2 weeks notice provided if you wish to discontinue the service
                </em>
              </span>
            </li>
          </ul>
        </div>
        <div className={style.singleCard}>
          <div className={style.topImg}>
            <img
              src="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/Icon-6.png"
              alt=""
              className={style.topFig}
            ></img>
          </div>
          <div className={style.centeredImg}>
            <img
              src="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hourly-min.png"
              alt=""
              className={style.centerFig}
            ></img>
          </div>
          <ul className={style.listMenu}>
            <li className={style.listItem}>
              <span className={style.listText}>At-least 10 hours per week</span>
            </li>

            <li className={style.listItem}>
              <span className={style.listText}>Dedicated Resource</span>
            </li>
            <li className={style.listItem}>
              <span className={style.listText}>One week free trial</span>
            </li>
            <li className={style.listItem}>
              <span className={style.listText}></span>
            </li>
            <li className={style.listItem}>
              <span className={style.listText}>
                <em>
                  2 weeks notice provided if you wish to discontinue the service
                </em>
              </span>
            </li>
          </ul>
        </div>
        <div className={style.singleCard}>
          <div className={style.topImg}>
            <img
              src="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/Icon-6.png"
              alt=""
              className={style.topFig}
            ></img>
          </div>
          <div className={style.centeredImg}>
            <img
              src="https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/Icon-5-1-1.png"
              alt=""
              className={style.centerFig}
            ></img>
          </div>
          <ul className={style.listMenu}>
            <li className={style.listItem}>
              <span className={style.listText}>Key Milestone</span>
            </li>
            <li className={style.listItem}>
              <span className={style.listText}>Timeline</span>
            </li>
            <li className={style.listItem}>
              <span className={style.listText}>Cost Estimates</span>
            </li>
            <li className={style.listItem}>
              <span className={style.listText}></span>
            </li>
            <li className={style.listItem}>
              <span className={style.listText}>
                <em></em>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
