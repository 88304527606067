import React, { useEffect } from "react";
import Main from "../../../Main/Main";
import Tabs from "../../Tabs/Tabs";
import Details from "../../Details/Details";
import DetailsData from "./DetailsTravelData";
function OnDemand({ title }) {
  useEffect(() => {
    document.title = title;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [title]);
  return (
    <div>
      <Main
        heading="TRAVEL & TOURISM"
        description="Redefining the future of travel and hospitality"
      />
      <Tabs active="3" />
      <Details data={DetailsData} />
    </div>
  );
}

export default OnDemand;
