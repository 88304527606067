const data = [
  {
    toggle: {
      title: "Cryptography & IOT",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-blue.svg"
    },
    content: {
      para: "We offer cryptography-based security solutions, and IOT consultancy to businesses across a wide range of sectors just so you can excel in providing secure IOT products."
    },
    key: "0"
  },
  {
    toggle: {
      title: "Protection from Data Loss and Attacks",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/laptop-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/moniter-blue.svg"
    },
    content: {
      para: "<strong>buggcy</strong> works to limit the access to protected systems and data to only authorized devices and users so that there is an added layer of security. Our work on data confidentiality and privacy include the Internet of Things (IOT), server & cloud platforms, firmware updates & patches, and other networks."
    },
    key: "1"
  },
  {
    toggle: {
      title: "Cut Your Operational Cost",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/hands-blue.svg"
    },
    content: {
      para: "With added security features like remote monitoring and control of devices, we reduce the cost of device maintenance and updates. Instead of sending a technician to fix a malfunction of device or system, remote monitoring would allow for more feasible alternatives."
    },
    key: "2"
  },
  {
    toggle: {
      title: "Build Your Reputation with <strong>buggcy</strong>",
      imgBef:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/disk-black.svg",
      imgAft:
        "https://<strong>buggcy</strong>.com/wp-content/uploads/2020/06/disk-blue.svg"
    },
    content: {
      para: "Through <strong>buggcy</strong>’s cryptography and encryption services, brands and organizations can compensate for their lost revenue. Secured devices allow opportunities for new revenue streams and better products allow for a better name in the competitive market."
    },
    key: "3"
  }
];

export default data;
