import React from "react";
import styles from "../Widget.module.css";
import style from "./Indutries.module.css";
import data from "../../../NavBar/IndustriesData";
import { Link } from "react-router-dom";
export default function Industries() {
  return (
    <div>
      <div className={style.titleDiv}>
        <h2 className={styles.widgetTitle}>INDUSTRIES</h2>
      </div>
      <div className={style.listDiv}>
        <ul className={style.listMainContainer}>
          {data.map(
            (item, index) => (
              <div key={index} className={style.listInnerDiv}>
                <li className={style.listItem}>
                  {/* <Link to={item.target} className={style.link}> */}
                  <Link to={"#"} className={style.link}>
                    {item.title}
                  </Link>
                </li>
              </div>
            ),
            []
          )}
        </ul>
      </div>
    </div>
  );
}
