import React from "react";
import style from "./Points.module.css";
import cx from "classnames";
function Points() {
  return (
    <div className={style.mainContainer}>
      <div className={style.innerContainer}>
        <div className={cx(style.singleCard, style.firstCard)}>
          <h1 className={style.cardHeading}>Powerful and reliable tools</h1>
          <p className={cx(style.cardDescription, style.firstDescription)}>
            Our belief in Scrum and Kanban to raise our pro activeness whereas
            you enjoy our work.
          </p>
        </div>
        <div className={cx(style.singleCard, style.secondCard)}>
          <h1 className={style.cardHeading}> Stretch your marketing dollar</h1>
          <p className={cx(style.cardDescription, style.secondDescription)}>
            Our up-to-date knowledge on subject matter helps us achieve
            high-quality technical skills in coding.
          </p>
        </div>

        <div className={cx(style.singleCard, style.thirdCard)}>
          <h1 className={style.cardHeading}>Focuses on your success</h1>
          <p className={cx(style.cardDescription, style.thirdDescription)}>
            Our accessibility to positive feedback allows us to aim for
            efficient and productive delivery.{" "}
          </p>
        </div>
        <div className={cx(style.singleCard, style.fourthCard)}>
          <h1 className={style.cardHeading}>WebFX is the choice</h1>
          <p className={cx(style.cardDescription, style.fourthDescription)}>
            We always make aim for scalable solutions so that you always
            maintain your efficiency & growth.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Points;
