import React from "react";
import style from "./TabsContent.module.css";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function TabsContent({ data }) {
  return (
    <div>
      <div className={style.mainContainer}>
        <div className={style.detailsDiv}>
          <h1 className={style.heading}>{data.p1.heading}</h1>
          <p className={style.description}>{data.p1.description}</p>
          <h1 className={style.heading}>{data.p2.heading}</h1>
          <p className={style.description}>{data.p2.description}</p>
          <Link to="/contact-us">
            <button className={style.learnMoreBtn}>
              Let's Talk <FiArrowRight className={style.arrow} />
            </button>
          </Link>
        </div>
        <div className={style.figDiv}>
          <img
            src={data.imgSrc}
            className={style.fig}
            alt={data.p1.heading}
          ></img>
        </div>
      </div>
    </div>
  );
}
